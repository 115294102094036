<template>
    <div class="my-4">
        <h5>
            Attached Form
        </h5>
        <select-relationship
            title="Form"
            :get-src="(id) => `/api/forms/${id}/fetchLess`"
            list-src="/api/forms/admin/list"
            list-key="forms"
            item-key="form"
            display-key="title"
            get-key="id"
            class="m-3"
            :value="value"
            @input="saveFormAttachment"
        ></select-relationship>

        <div
            v-if="value"
            class="btn-group"
        >
            <router-link
                class="btn btn-link"
                :to="{ name: 'edit_form', params: { id: value }}"
            >
                <font-awesome-icon icon="pencil-alt" class="mr-1" />
                Edit Form
            </router-link>
            <router-link
                class="btn btn-link"
                :to="{ name: 'get_form_responses', params: { id: value }}"
            >
                <font-awesome-icon icon="table" class="mr-1" />
                View Responses
            </router-link>
            <router-link
                class="btn btn-link"
                :to="{ name: 'change_form_settings', params: { id: value }}"
            >
                <font-awesome-icon icon="cog" class="mr-1" />
                View Settings
            </router-link>
            <button
                class="btn btn-link"
                @click="unattachForm()"
            >
                <font-awesome-icon icon="unlink" class="mr-1" />
                Unattach Form
            </button>
        </div>
    </div>
</template>
<script>
import SelectRelationship from '@/components/SelectRelationship.vue';

export default {
    name: "class-attached-form",
    components: { SelectRelationship },
    props: ["classId", "value"],
    data() {
        return {
            formId: this.attachedFormId || null
        }
    },
    methods: {
        saveFormAttachment(form_id) {
            this.saving = true;
            this.$api.post(
                `/api/admin/attach-class-form`,
                {
                    class_id: this.classId,
                    form_id
                }
            ).then(({ data }) => {
                this.$bvToast.toast(data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                if (data.success) {
                    this.$emit("input", form_id);
                }
                this.saving = false;
            });
        },
        unattachForm() {
            this.$api.post(
                `/api/admin/unattach-class-form`,
                { class_id: this.classId }
            ).then(() => {
                this.$emit("input", null);
            })
        }
    }
}
</script>