<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <router-link
                :to="{ name: 'classes' }"
                class="sc-btn pill"
                style="min-width: 150px"
            >
                <b-icon-arrow-left-circle scale="1.5" class="mr-2"></b-icon-arrow-left-circle> Back: Classes
            </router-link>
            <b-card
                class="mt-4"
            >
                <h4>
                    {{ currentClass.class_name }}
                    <router-link
                        class="ml-2"
                        :to="{ name: 'admin_form', params: { formKey: 'edit-class' }, query: { id: currentClass.id } }"
                    >
                        <font-awesome-icon
                            icon="pencil-alt"
                        ></font-awesome-icon>
                    </router-link>
                </h4>
                <hr/>
                <div>
                    <!--<font-awesome-icon icon="file-alt" class="mr-2"></font-awesome-icon>-->
                    {{ currentClass.description }}
                </div>
            </b-card>
            <TeachingAssignment
                :class_id="currentClass.id"
            ></TeachingAssignment>
            <Enrollment
                :key="enrollmentListUpdateKey"
                :class_id="currentClass.id"
            ></Enrollment>
            <EnrollmentWaitingList
                :class_id="currentClass.id"
                @enroll="updateEnrollment"
            ></EnrollmentWaitingList>
            <ClassAttachedForm
                :class-id="currentClass.id"
                v-model="currentClass.form_id"
            ></ClassAttachedForm>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import ClassAttachedForm from './ClassAttachedForm.vue'
import Enrollment            from './Enrollment.vue'
import EnrollmentWaitingList from './EnrollmentWaitingList.vue'
import TeachingAssignment    from './TeachingAssignment.vue'

export default {
    props: ["id"],
    components: {
        Enrollment,
        EnrollmentWaitingList,
        TeachingAssignment,
        ClassAttachedForm
    },
    data() {
        return {
            loading                 : null,
            enrollmentListUpdateKey : 0,
            currentClass            : {}
        };
    },
    methods: {
        loadClass() {
            this.loading = true;
            this.$api.get(`/api/admin/fetch-class`, { params: { id: this.id } }).then(({ data }) => {
                this.currentClass = data.record;
                this.loading      = false;
            });
        },
        updateEnrollment() {
            this.enrollmentListUpdateKey++;
        }
    },
    mounted() {
        this.loadClass();
    }
}
</script>